import 'aos/dist/aos.css';
import './static/styles/style.css';

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';

import ChargeniePolicyPage from './pages/chargenie-policy';
import HomePage from './pages/home';
import PolicyPage from './pages/policy';
import PrivacyPolicyPage from './pages/privacy-policy';

function App() {
	return (
		<ParallaxProvider>
			<div className="App">
				<Router>
					<Switch>
						<Route exact path="/" render={() => <HomePage />} />
						<Route exact path="/policy" render={() => <PolicyPage />} />
						<Route
							exact
							path="/chargenie-policy"
							render={() => <ChargeniePolicyPage />}
						/>
						<Route
							exact
							path="/privacy-policy"
							render={() => <PrivacyPolicyPage />}
						/>
					</Switch>
				</Router>
			</div>
		</ParallaxProvider>
	)
}
export default App
