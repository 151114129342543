import React, { Component } from 'react'
import { ICard } from '../../interfaces/card'
import './style.css'

export default class Card extends Component<ICard> {
	render() {
		return (
			<div className="card-wrapper">
				<div className="card-img-wrapper">
					<img
						className="card-objective-img"
						src={this.props.img}
						alt="objective-card-img"
						style={{ maxWidth: this.props.imgWidth }}
						width="100%"
					/>
				</div>
				<div className="card-topic-wrapper">
					<h2> {this.props.topic} </h2>
				</div>
				<p>{this.props.describe}</p>
			</div>
		)
	}
}
