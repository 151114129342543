import './style.css'

import React, { Component } from 'react'

export default class Footer extends Component {
	render() {
		return (
			<div className="footer-wrapper">
				<p>
					©2021. Merefine Co., Ltd. | <a href="/policy">Privacy </a> |{' '}
					<a href="/privacy-policy">นโยบายการคุ้มครองข้อมูลส่วนบุคคล </a>
				</p>
			</div>
		)
	}
}
