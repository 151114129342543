import React, { Component } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './style.css'
import OurServiceImg from '../../static/image/our_service.svg'

export default class Banner extends Component {
	componentDidMount() {
		AOS.init({ once: true, delay: 100, duration: 800 })
	}

	render() {
		return (
			<div className="banner-wrapper">
				<div className="banner-text-wrapper">
					<h1 data-aos="fade-right">Our Services & Products</h1>

					<p data-aos="fade-left">
						We provide an efficiency application to all that you want whether
						development or design.
					</p>

					<img className="banner-img" src={OurServiceImg} alt="banner-img" />
				</div>
			</div>
		)
	}
}
