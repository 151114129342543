import './style.css'

import React, { Component } from 'react'

import ClaimingImg from '../../static/image/1_claim.svg'
import MeclaimImg from '../../static/image/meclaim_app.svg'
import ProcheckerClientImg from '../../static/image/pro_checker_client.png'
import ProcheckerTopicImg from '../../static/image/pro_checker_topic.svg'
import ProcheckerImg from '../../static/image/prochecker_app.png'
import CardProduct from '../CardProduct/CardProduct'

const productList: Array<Object> = [
	{
		index: '1',
		topic: 'MECLAIM',
		topicImg: ClaimingImg,
		describe: 'The application supports insurance organizations.',
		tech: ['Motor', 'Inspector', 'Assist', 'Website', 'Andriod'],
		img: MeclaimImg,
	},
	{
		index: '2',
		topic: 'Pro Checker',
		topicImg: ProcheckerTopicImg,
		describe: 'The application supports insurance organizations.',
		tech: ['Motor', 'Website', 'Andriod'],
		clientImg: ProcheckerClientImg,
		img: ProcheckerImg,
	},
]

export default class ProductNew extends Component {
	render() {
		const cardList: any = productList.map((product: any, index: number) => {
			return (
				<div className="card" key={index}>
					<CardProduct
						index={product.index}
						topicImg={product.topicImg}
						topic={product.topic}
						describe={product.describe}
						img={product.img}
						tech={product.tech}
						clientImg={product.clientImg}
					/>
				</div>
			)
		})

		return <div className="product-new">{cardList}</div>
	}
}
