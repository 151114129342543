import 'aos/dist/aos.css'

import AOS from 'aos'
import React, { useEffect, useRef } from 'react'

import Banner from '../components/Banner/Banner'
import Client from '../components/Client/Client'
import Contact from '../components/Contact/Contact'
import Footer from '../components/Footer/Footer'
import Globe from '../components/Globe/Globe'
import Header from '../components/Hearder/Header'
import Navbar from '../components/Navbar/Navbar'
import Objective from '../components/Objective/Objective'
import Project from '../components/Product/Product'

const scrollToRef = (ref: any) => {
	const disObj: number = 50
	window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop - disObj })
}

function App() {
	const productRef: any = useRef(null)
	const clientRef: any = useRef(null)
	const contactRef: any = useRef(null)
	const objectRef: any = useRef(null)
	const homeRef: any = useRef(null)

	const executeScroll = (ref: any) => scrollToRef(ref)

	useEffect(() => {
		AOS.init()
	})

	return (
		<div>
			<Navbar
				refNavbar={[productRef, clientRef, contactRef, homeRef]}
				executeScroll={(ref: any) => executeScroll(ref)}
			/>
			<div ref={homeRef}>
				<Globe />
				<Header
					refNavbar={[productRef, contactRef, objectRef]}
					executeScroll={(ref: any) => executeScroll(ref)}
				/>
			</div>
			<div ref={clientRef}>
				<Client />
			</div>

			<div className="p-relative" ref={objectRef}>
				<Objective />
			</div>
			<div ref={productRef}>
				<Banner />
			</div>

			<div>
				<Project />
			</div>

			<div ref={contactRef}>
				<Contact />
			</div>
			<Footer />
		</div>
	)
}
export default App
