import React from 'react'

const PrivacyPolicy = () => {
	return (
		<div className="policy-wrapper">
			<div
				style={{
					width: '210mm',
					marginLeft: 'auto',
					marginRight: 'auto',
					marginTop: '50px',
				}}
			>
				<h1>นโยบายการคุ้มครองข้อมูลส่วนบุคคล (Privacy Policy)</h1>
				<h2>บริษัท มีรีไฟน์ จำกัด</h2>
				<br />
				<h3>บทนำ</h3>
				<p>
					บริษัท มีรีไฟน์ จำกัด
					ตระหนักถึงความสำคัญของข้อมูลส่วนบุคคลและข้อมูลอื่น (รวมเรียกว่า “
					<strong>ข้อมูล</strong>”) สามารถเชื่อมั่นได้ว่า บริษัท มีรีไฟน์ จำกัด
					มีความโปร่งใสและความรับผิดชอบในการเก็บรวบรวม
					ใช้หรือเปิดเผยข้อมูลตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
					(“<strong>กฎหมายคุ้มครองข้อมูลส่วนบุคคล</strong>”)
					รวมถึงกฎหมายอื่นที่เกี่ยวข้อง นโยบายการคุ้มครองข้อมูลส่วนบุคคล (“
					<strong>นโยบาย</strong>”)
					นี้จึงได้ถูกจัดทำขึ้นเพื่อชี้แจงถึงรายละเอียดเกี่ยวกับการเก็บรวบรวม
					ใช้หรือเปิดเผย (รวมเรียกว่า “<strong>ประมวลผล</strong>”)
					ข้อมูลส่วนบุคคลซึ่งดำเนินการโดย บริษัท มีรีไฟน์ จำกัด
					รวมถึงเจ้าหน้าที่และบุคคลที่เกี่ยวข้องผู้ดำเนินการแทนหรือในนามของ
					บริษัท มีรีไฟน์ จำกัด โดยมีเนื้อหาสาระดังต่อไปนี้
				</p>
				<br />
				<h3>ขอบเขตการบังคับใช้นโยบาย</h3>
				<p>
					นโยบายนี้ใช้บังคับกับ
					<strong>ข้อมูลส่วนบุคคลของบุคคลซึ่งมีความสัมพันธ์กับ</strong>
					บริษัท มีรีไฟน์ จำกัด ในปัจจุบันและที่อาจมีในอนาคต
					ซึ่งถูกประมวลผลข้อมูลส่วนบุคคลโดย บริษัท มีรีไฟน์ จำกัด เจ้าหน้าที่
					พนักงานตามสัญญา หน่วยธุรกิจหรือหน่วยงานรูปแบบอื่นที่ดำเนินการโดย
					บริษัท มีรีไฟน์ จำกัด
					และรวมถึงคู่สัญญาหรือบุคคลภายนอกที่ประมวลผลข้อมูลส่วนบุคคลแทนหรือในนามของ
					บริษัท มีรีไฟน์ จำกัด (“<strong>ผู้ประมวลผลข้อมูลส่วนบุคคล</strong>”)
					ภายใต้ผลิตภัณฑ์และบริการต่าง ๆ เช่น เว็บไซต์ ระบบ แอปพลิเคชัน เอกสาร
					หรือบริการในรูปแบบอื่นที่ควบคุมดูแลโดย บริษัท มีรีไฟน์ จำกัด
					(รวมเรียกว่า “<strong>บริการ</strong>”)
				</p>
				<p>
					บุคคลมีความสัมพันธ์กับ บริษัท มีรีไฟน์ จำกัด ตามความในวรรคแรก รวมถึง
					<ol>
						<li>ลูกค้าบุคคลธรรมดา</li>
						<li>เจ้าหน้าที่หรือผู้ปฏิบัติงาน ลูกจ้าง</li>
						<li>คู่ค้าและผู้ให้บริการซึ่งเป็นบุคคลธรรมดา</li>
						<li>
							กรรมการ ผู้รับมอบอำนาจ ผู้แทน ตัวแทน ผู้ถือหุ้น ลูกจ้าง
							หรือบุคคลอื่นที่มีความสัมพันธ์ในรูปแบบเดียวกันของนิติบุคคลที่มีความสัมพันธ์กับบริษัท
							มีรีไฟน์ จำกัด
						</li>
						<li> ผู้ใช้งานผลิตภัณฑ์หรือบริการของ บริษัท มีรีไฟน์ จำกัด</li>
						<li>
							ผู้เข้าชมหรือใช้งานเว็บไซต์{' '}
							<a href="https://merefine.io" style={{ color: '#3d80fa' }}>
								{' '}
								merefine.io{' '}
							</a>{' '}
							รวมทั้งระบบ แอปพลิเคชัน อุปกรณ์
							หรือช่องทางการสื่อสารอื่นซึ่งควบคุมดูแลโดย บริษัท มีรีไฟน์ จำกัด
						</li>
						<li>
							บุคคลอื่นที่ บริษัท มีรีไฟน์ จำกัด เก็บรวบรวมข้อมูลส่วนบุคคล เช่น
							ผู้รับประโยชน์ในกรมธรรม์ประกันภัย ผู้สมัครงาน
							ครอบครัวของเจ้าหน้าที่ ผู้ค้ำประกัน เป็นต้น
						</li>
					</ol>
				</p>
				<p>
					นอกจากนโยบายฉบับนี้แล้ว บริษัท มีรีไฟน์ จำกัด อาจกำหนดให้มี
					<strong>คำประกาศเกี่ยวกับความเป็นส่วนตัว</strong> (“
					<strong>ประกาศ</strong>”) สำหรับผลิตภัณฑ์หรือบริการของ บริษัท มีรีไฟน์
					จำกัด
					เพื่อชี้แจงให้เจ้าของข้อมูลส่วนบุคคลซึ่งเป็นผู้ใช้บริการได้ทราบถึงข้อมูลส่วนบุคคลที่ถูกประมวลผล
					วัตถุประสงค์และเหตุผลอันชอบด้วยกฎหมายในการประมวลผล
					ระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล
					รวมถึงสิทธิในข้อมูลส่วนบุคคลที่เจ้าของข้อมูลส่วนบุคคลพึงมีในผลิตภัณฑ์หรือบริการนั้นเป็นการเฉพาะเจาะจง
				</p>
				<p>
					ทั้งนี้
					ในกรณีที่มีความขัดแย้งกันในสาระสำคัญระหว่างความในประกาศเกี่ยวกับความเป็นส่วนตัวและนโยบายนี้
					ให้ถือตามความในประกาศเกี่ยวกับความเป็นส่วนตัวของบริการนั้น
				</p>
				<br />
				<h3>คำนิยาม</h3>
				<p>
					<p>
						<strong>ข้อมูลส่วนบุคคล</strong> หมายถึง ข้อมูลเกี่ยวกับบุคคลธรรมดา
						ซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม
						แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ
					</p>
					<p>
						<strong>ข้อมูลส่วนบุคคลอ่อนไหว</strong> หมายถึง
						ข้อมูลส่วนบุคคลตามที่ถูกบัญญัติไว้ในมาตรา 26
						แห่งพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 ซึ่งได้แก่
						ข้อมูลเชื้อชาติ เผ่าพันธุ์ ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ
						ศาสนาหรือปรัชญา พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ
						ความพิการ ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม ข้อมูลชีวภาพ
						หรือข้อมูลอื่นใดซึ่งกระทบต่อเจ้าของข้อมูลส่วนบุคคลในทำนองเดียวกันตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลประกาศกำหนด
					</p>
					<p>
						<strong>การประมวลผลข้อมูลส่วนบุคคล</strong> หมายถึง การดำเนินการใด ๆ
						กับข้อมูลส่วนบุคคล เช่น เก็บรวบรวม บันทึก สำเนา จัดระเบียบ เก็บรักษา
						ปรับปรุง เปลี่ยนแปลง ใช้ กู้คืน เปิดเผย ส่งต่อ เผยแพร่ โอน รวม ลบ
						ทำลาย เป็นต้น
					</p>
					<p>
						<strong>เจ้าของข้อมูลส่วนบุคคล</strong> หมายถึง
						บุคคลธรรมดาซึ่งเป็นเจ้าของข้อมูลส่วนบุคคลที่ บริษัท มีรีไฟน์ จำกัด
						เก็บรวบรวม ใช้ หรือเปิดเผย
					</p>
					<p>
						<strong>ผู้ควบคุมข้อมูลส่วนบุคคล</strong> หมายถึง
						บุคคลหรือนิติบุคคลซึ่งมีอำนาจหน้าที่ตัดสินใจเกี่ยวกับการเก็บรวบรวม
						ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
					</p>
					<p>
						<strong>ผู้ประมวลผลข้อมูลส่วนบุคคล</strong> หมายถึง
						บุคคลหรือนิติบุคคลซึ่งดำเนินการเกี่ยวกับการเก็บรวบรวม ใช้
						หรือเปิดเผยข้อมูลส่วนบุคคลตามคำสั่งหรือในนามของผู้ควบคุมข้อมูลส่วนบุคคล
						ทั้งนี้
						บุคคลหรือนิติบุคคลซึ่งดำเนินการดังกล่าวไม่เป็นผู้ควบคุมข้อมูลส่วนบุคคล
					</p>
				</p>
				<br />
				<h3>
					แหล่งที่มาของข้อมูลส่วนบุคคลที่ บริษัท มีรีไฟน์ จำกัด เก็บรวบรวม
				</h3>
				<p>
					บริษัท มีรีไฟน์ จำกัด เก็บรวบรวมหรือได้มาซึ่งข้อมูลส่วนบุคคลประเภทต่าง
					ๆ จากแหล่งข้อมูลดังต่อไปนี้
				</p>
				<p>
					1) ข้อมูลส่วนบุคคลที่ บริษัท มีรีไฟน์ จำกัด
					เก็บรวบรวมจากเจ้าของข้อมูลส่วนบุคคลโดยตรงในช่องทางให้บริการต่าง ๆ เช่น
					ขั้นตอนการสมัคร ลงทะเบียน สมัครงาน ลงนามในสัญญา เอกสาร
					หรือใช้งานผลิตภัณฑ์ บริการ หรือช่องทางให้บริการอื่นที่ควบคุมดูแลโดย
					บริษัท มีรีไฟน์ จำกัด หรือเมื่อเจ้าของข้อมูลส่วนบุคคลติดต่อสื่อสารกับ
					บริษัท มีรีไฟน์ จำกัด ณ
					ที่ทำการหรือผ่านช่องทางติดต่ออื่นที่ควบคุมดูแลโดย บริษัท มีรีไฟน์
					จำกัด เป็นต้น
				</p>
				<p>
					2) ข้อมูลที่ บริษัท มีรีไฟน์ จำกัด
					เก็บรวบรวมจากการที่เจ้าของข้อมูลส่วนบุคคลเข้าใช้งานเว็บไซต์
					ผลิตภัณฑ์หรือบริการอื่น ๆ ตามสัญญาหรือตามพันธกิจ เช่น
					การติดตามพฤติกรรมการใช้งานเว็บไซต์ ผลิตภัณฑ์หรือบริการของ บริษัท
					มีรีไฟน์ จำกัด ด้วยการใช้คุกกี้ (Cookies)
					หรือจากซอฟต์แวร์บนอุปกรณ์ของเจ้าของข้อมูลส่วนบุคคล เป็นต้น
				</p>
				<p>
					3) ข้อมูลส่วนบุคคลที่ บริษัท มีรีไฟน์ จำกัด
					เก็บรวบรวมจากแหล่งอื่นนอกจากเจ้าของข้อมูลส่วนบุคคล
					โดยที่แหล่งข้อมูลดังกล่าวมีอำนาจหน้าที่
					มีเหตุผลที่ชอบด้วยกฎหมายหรือได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคลแล้วในการเปิดเผยข้อมูลแก่
					บริษัท มีรีไฟน์ จำกัด เช่น
					การเชื่อมโยงบริการดิจิทัลของหน่วยงานของเอกชนและของรัฐในการให้บริการแบบเบ็ดเสร็จแก่เจ้าของข้อมูลส่วนบุคคลเอง
					การรับข้อมูลส่วนบุคคลจากหน่วยงานชองเอกชนและของรัฐแห่งอื่นในฐานะที่
					บริษัท มีรีไฟน์ จำกัด
					มีหน้าที่ตามพันธกิจในการดำเนินการจัดให้มีศูนย์แลกเปลี่ยนข้อมูลกลางเพื่อสนับสนุนการดำเนินการของหน่วยงานของเอกชนและของรัฐในการให้บริการประชาชนผ่านระบบดิจิทัล
					รวมถึงจากความจำเป็นเพื่อให้บริการตามสัญญาที่อาจมีการแลกเปลี่ยนข้อมูลส่วนบุคคลกับหน่วยงานของคู่สัญญาได้
				</p>
				<p>
					นอกจากนี้
					ยังหมายความรวมถึงกรณีที่ผู้ให้ข้อมูลส่วนบุคคลของบุคคลภายนอกแก่ บริษัท
					มีรีไฟน์ จำกัด ดังนี้
					ท่านมีหน้าที่รับผิดชอบในการแจ้งรายละเอียดตามนโยบายนี้หรือประกาศของผลิตภัณฑ์หรือบริการ
					ตามแต่กรณี ให้บุคคลดังกล่าวทราบ
					ตลอดจนขอความยินยอมจากบุคคลนั้นหากเป็นกรณีที่ต้องได้รับความยินยอมในการเปิดเผยข้อมูลแก่
					บริษัท มีรีไฟน์ จำกัด
				</p>
				<p>
					ทั้งนี้
					ในกรณีที่เจ้าของข้อมูลส่วนบุคคลปฏิเสธไม่ให้ข้อมูลที่มีความจำเป็นในการให้บริการของ
					บริษัท มีรีไฟน์ จำกัด อาจเป็นผลให้ บริษัท มีรีไฟน์ จำกัด
					ไม่สามารถให้บริการนั้นแก่เจ้าของข้อมูลส่วนบุคคลดังกล่าวได้ทั้งหมดหรือบางส่วน
				</p>
				<br />
				<h3>ฐานกฎหมายในการเก็บรวบรวมข้อมูลส่วนบุคคล</h3>
				<p>
					บริษัท มีรีไฟน์ จำกัด
					พิจารณากำหนดฐานกฎหมายในการเก็บรวบรวมข้อมูลส่วนบุคคลของท่านตามความเหมาะสมและตามบริบทของการให้บริการ
					ทั้งนี้ ฐานกฎหมายในการเก็บรวบรวมข้อมูลส่วนบุคคลที่ บริษัท มีรีไฟน์
					จำกัด ใช้ ประกอบด้วย
				</p>
				<table>
					<tr>
						<td style={{ width: 200 }}>
							เป็นการจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมาย
						</td>
						<td>
							เพื่อประโยชน์โดยชอบด้วยกฎหมายของ บริษัท มีรีไฟน์ จำกัด
							และของบุคคลอื่น
							ซึ่งประโยชน์ดังกล่าวมีความสำคัญไม่น้อยไปกว่าสิทธิขั้นพื้นฐานในข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล
							เช่น การประมวลผลข้อมูลส่วนบุคคลเพื่อกิจการภายในของ บริษัท มีรีไฟน์
							จำกัด เป็นต้น
						</td>
					</tr>
					<tr>
						<td>เพื่อการปฏิบัติตามสัญญา</td>
						<td>
							เพื่อให้ บริษัท มีรีไฟน์ จำกัด สามารถปฏิบัติหน้าที่ตามสัญญา
							หรือดำเนินการอันเป็นความจำเป็นต่อการเข้าทำสัญญาซึ่งท่านเป็นคู่สัญญากับ
							บริษัท มีรีไฟน์ จำกัด เช่น การจ้างงาน จ้างทำของ
							การทำบันทึกข้อตกลงความร่วมมือหรือสัญญาในรูปแบบอื่น เป็นต้น
						</td>
					</tr>
					<tr>
						<td>ความยินยอมของท่าน</td>
						<td>
							เพื่อการเก็บรวบรวม ใช้หรือเปิดเผยข้อมูลส่วนบุคคลในกรณีที่ บริษัท
							มีรีไฟน์ จำกัด จำเป็นต้องได้รับความยินยอมจากท่าน
							โดยได้มีการแจ้งวัตถุประสงค์ของการเก็บรวบรวม ใช้
							หรือเปิดเผยข้อมูลส่วนบุคคลก่อนการขอความยินยอมแล้ว เช่น
							การเก็บรวบรวมข้อมูลส่วนบุคคลอ่อนไหวด้วยวัตถุประสงค์ที่ไม่เป็นไปตามข้อยกเว้นมาตรา
							24 หรือ 26 แห่งพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
							หรือการนำเสนอ
							ประชาสัมพันธ์ผลิตภัณฑ์และบริการของคู่สัญญาหรือพันธมิตรทางธุรกิจแก่ท่าน
							เป็นต้น
						</td>
					</tr>
				</table>

				<p>
					ในกรณีที่ บริษัท มีรีไฟน์ จำกัด
					มีความจำเป็นต้องเก็บรวบรวมข้อมูลส่วนบุคคลของท่านเพื่อการปฏิบัติตามสัญญา
					การปฏิบัติหน้าที่ตามกฎหมายหรือเพื่อความจำเป็นในการเข้าทำสัญญา
					หากท่านปฏิเสธไม่ให้ข้อมูลส่วนบุคคลหรือ
					คัดค้านการดำเนินการประมวลผลตามวัตถุประสงค์ของกิจกรรม อาจมีผลทำให้
					บริษัท มีรีไฟน์ จำกัด
					ไม่สามารถดำเนินการหรือให้บริการตามที่ท่านร้องขอได้ทั้งหมดหรือบางส่วน
				</p>
				<br />
				<h3>ประเภทของข้อมูลส่วนบุคคลที่ บริษัท มีรีไฟน์ จำกัด เก็บรวบรวม</h3>
				<p>
					บริษัท มีรีไฟน์ จำกัด อาจเก็บรวบรวมหรือได้มาซึ่งข้อมูลดังต่อไปนี้
					ซึ่งอาจรวมถึงข้อมูลส่วนบุคคลของท่าน ทั้งนี้
					ขึ้นอยู่กับบริการที่ท่านใช้หรือบริบทความสัมพันธ์ที่ท่านมีกับ บริษัท
					มีรีไฟน์ จำกัด
					รวมถึงข้อพิจารณาอื่นที่มีผลกับการเก็บรวบรวมข้อมูลส่วนบุคคล
					โดยประเภทของข้อมูลที่ระบุไว้ดังต่อไปนี้เป็นเพียงกรอบการเก็บรวบรวมข้อมูลส่วนบุคคลของ
					บริษัท มีรีไฟน์ จำกัด เป็นการทั่วไป ทั้งนี้
					เฉพาะข้อมูลที่เกี่ยวข้องกับผลิตภัณฑ์หรือบริการที่ท่านใช้งานหรือมีความสัมพันธ์ด้วยเท่านั้นที่จะมีผลบังคับใช้
				</p>
				<table>
					<tr>
						<th>ประเภทข้อมูลส่วนบุคคล</th>
						<th>รายละเอียดและตัวอย่าง</th>
					</tr>
					<tr>
						<td style={{ width: 200 }}>ข้อมูลเฉพาะตัวบุคคล</td>
						<td>
							ข้อมูลระบุชื่อเรียกของท่านหรือข้อมูลจากเอกสารราชการที่ระบุข้อมูลเฉพาะตัวของท่าน
							เช่น คำนำหน้าชื่อ ชื่อ นามสกุล ชื่อกลาง ชื่อเล่น ลายมือชื่อ
							เลขที่บัตรประจำตัวประชาชน สัญชาติ เลขที่ใบขับขี่
							เลขที่หนังสือเดินทาง ข้อมูลทะเบียนบ้าน หมายเลขใบประกอบการ
							หมายเลขใบอนุญาตการประกอบวิชาชีพ (สำหรับแต่ละอาชีพ)
							หมายเลขประจำตัวผู้ประกันตน หมายเลขประกันสังคม เป็นต้น
						</td>
					</tr>
					<tr>
						<td>ข้อมูลเกี่ยวกับคุณลักษณะของบุคคล</td>
						<td>
							ข้อมูลรายละเอียดเกี่ยวกับตัวท่าน เช่น วัน เดือน ปีเกิด เพศ ส่วนสูง
							น้ำหนัก อายุ สถานภาพการสมรส สถานภาพการเกณฑ์ทหาร รูปถ่าย ภาษาพูด
							ข้อมูลพฤติกรรม ความชื่นชอบ ข้อมูลการเป็นบุคคลล้มละลาย
							ข้อมูลการเป็นคนไร้ความสามารถหรือคนเสมือนไร้ความสามารถ เป็นต้น
						</td>
					</tr>
					<tr>
						<td>ข้อมูลสำหรับการติดต่อ</td>
						<td>
							ข้อมูลเพื่อการติดต่อท่าน เช่น เบอร์โทรศัพท์บ้าน
							เบอร์โทรศัพท์เคลื่อนที่ หมายเลขโทรสาร อีเมล ที่อยู่ทางไปรษณีย์บ้าน
							ชื่อผู้ใช้งานในสังคมออนไลน์ (Line ID, MS Teams)
							แผนที่ตั้งของที่พัก เป็นต้น
						</td>
					</tr>
					<tr>
						<td>ข้อมูลเกี่ยวกับการทำงานและการศึกษา</td>
						<td>
							รายละเอียดการจ้างงาน รวมถึงประวัติการทำงานและประวัติการศึกษา เช่น
							ประเภทการจ้างงาน อาชีพ ยศ ตำแหน่ง หน้าที่ ความเชี่ยวชาญ
							สถานภาพใบอนุญาตทำงาน ข้อมูลบุคคลอ้างอิง หมายเลขประจำตัวผู้เสียภาษี
							ประวัติการดำรงตำแหน่ง ประวัติการทำงาน ข้อมูลเงินเดือน วันเริ่มงาน
							วันออกจากงาน ผลการประเมิน สวัสดิการและสิทธิประโยชน์
							พัสดุในครอบครองของผู้ปฏิบัติงาน ผลงาน หมายเลขบัญชีธนาคาร
							สถาบันการศึกษา วุฒิการศึกษา ผลการศึกษา วันที่สำเร็จการศึกษา
							เป็นต้น
						</td>
					</tr>
					<tr>
						<td>ข้อมูลเกี่ยวกับกรมธรรม์ประกันภัย</td>
						<td>
							รายละเอียดเกี่ยวกับกรมธรรม์ประกันภัย เช่น ผู้รับประกันภัย
							ผู้เอาประกันภัย ผู้รับประโยชน์ หมายเลขกรมธรรม์ ประเภทกรมธรรม์
							วงเงินคุ้มครอง ข้อมูลเกี่ยวกับการเคลม เป็นต้น
						</td>
					</tr>
					<tr>
						<td>ข้อมูลเกี่ยวกับความสัมพันธ์ทางสังคม</td>
						<td>
							ข้อมูลความสัมพันธ์ทางสังคมของท่าน เช่น การดำรงตำแหน่งกรรมการ
							ความสัมพันธ์กับผู้ปฏิบัติงานของ บริษัท มีรีไฟน์ จำกัด
							ข้อมูลการเป็นผู้มีสัญญาจ้างกับ บริษัท มีรีไฟน์ จำกัด
							ข้อมูลการเป็นผู้มีส่วนได้เสียในกิจการที่ทำกับ บริษัท มีรีไฟน์
							จำกัด เป็นต้น
						</td>
					</tr>
					<tr>
						<td>ข้อมูลเกี่ยวกับการใช้บริการของ บริษัท มีรีไฟน์ จำกัด</td>
						<td>
							รายละเอียดเกี่ยวกับผลิตภัณฑ์หรือบริการของ บริษัท มีรีไฟน์ จำกัด
							เช่น ชื่อบัญชีผู้ใช้งาน รหัสผ่าน หมายเลข PIN ข้อมูล Single Sign-on
							(SSO ID) รหัส OTP ข้อมูลการจราจรทางคอมพิวเตอร์ ข้อมูลระบุพิกัด
							ภาพถ่าย วีดีโอ บันทึกเสียง ข้อมูลพฤติกรรมการใช้งาน
							(เว็บไซต์ที่อยู่ในความดูแลของ บริษัท มีรีไฟน์ จำกัด เช่น
							meclaim.io หรือแอปพลิเคชันต่าง ๆ ประวัติการสืบค้น
							คุกกี้หรือเทคโนโลยีในลักษณะเดียวกัน หมายเลขอุปกรณ์ (Device ID)
							ประเภทอุปกรณ์ รายละเอียดการเชื่อมต่อ ข้อมูล Browser ภาษาที่ใช้งาน
							ระบบปฏิบัติการที่ใช้งาน เป็นต้น
						</td>
					</tr>
					<tr>
						<td>ข้อมูลส่วนบุคคลที่มีความละเอียดอ่อน</td>
						<td>
							ข้อมูลส่วนบุคคลที่มีความละเอียดอ่อนของท่าน เช่น เชื้อชาติ
							ข้อมูลศาสนา ข้อมูลความพิการ ข้อมูลความเห็นทางการเมือง
							ประวัติอาชญากรรม ข้อมูลชีวภาพ (ข้อมูลภาพจำลองใบหน้า)
							ข้อมูลเกี่ยวกับสุขภาพ เป็นต้น
						</td>
					</tr>
				</table>
				<br />
				<h3>คุกกี้</h3>
				<p>
					บริษัท มีรีไฟน์ จำกัด
					เก็บรวบรวมและใช้คุกกี้รวมถึงเทคโนโลยีอื่นในลักษณะเดียวกันในเว็บไซต์ที่อยู่ภายใต้ความดูแลของ
					บริษัท มีรีไฟน์ จำกัด เช่น{' '}
					<a href="https://merefine.io" style={{ color: '#3d80fa' }}>
						{' '}
						merefine.io{' '}
					</a>{' '}
					หรือบนอุปกรณ์ของท่านตามแต่บริการที่ท่านใช้งาน ทั้งนี้
					เพื่อการดำเนินการด้านความปลอดภัยในการให้บริการของ บริษัท มีรีไฟน์
					จำกัด
					และเพื่อให้ท่านซึ่งเป็นผู้ใช้งานได้รับความสะดวกและประสบการณ์ที่ดีในการใช้งานบริการของ
					บริษัท มีรีไฟน์ จำกัด
					และข้อมูลเหล่านี้จะถูกนำไปเพื่อปรับปรุงเว็บไซต์ของ บริษัท มีรีไฟน์
					จำกัด ให้ตรงกับความต้องการของท่านมากยิ่งขึ้น
					โดยท่านสามารถตั้งค่าหรือลบการใช้งานคุกกี้ได้ด้วยตนเองจากการตั้งค่าในเว็บเบราว์เซอร์
					(Web Browser) ของท่าน
				</p>
				<br />
				<h3>
					ข้อมูลส่วนบุคคลของผู้เยาว์ คนไร้ความสามารถและคนเสมือนไร้ความสามารถ
				</h3>
				<p>
					กรณีที่ บริษัท มีรีไฟน์ จำกัด
					ทราบว่าข้อมูลส่วนบุคคลที่จำเป็นต้องได้รับความยินยอมในการเก็บรวบรวม
					เป็นของเจ้าของข้อมูลส่วนบุคคลซึ่งเป็นผู้เยาว์
					คนไร้ความสามารถหรือคนเสมือนไร้ความสามารถ บริษัท มีรีไฟน์ จำกัด
					จะไม่ทำการเก็บรวบรวมข้อมูลส่วนบุคคลนั้นจนกว่าจะได้รับความยินยอมจากผู้ใช้อำนาจปกครองที่มีอำนาจกระทำการแทนผู้เยาว์
					หรือผู้อนุบาล หรือผู้พิทักษ์ตามแต่กรณี ทั้งนี้
					เป็นไปตามเงื่อนไขที่กฎหมายกำหนด
				</p>
				<p>
					กรณีที่ บริษัท มีรีไฟน์ จำกัด
					ไม่ทราบมาก่อนว่าเจ้าของข้อมูลส่วนบุคคลเป็นผู้เยาว์
					คนไร้ความสามารถหรือคนเสมือนไร้ความสามารถ และมาพบในภายหลังว่า บริษัท
					มีรีไฟน์ จำกัด
					ได้เก็บรวบรวมข้อมูลของเจ้าของข้อมูลส่วนบุคคลดังกล่าวโดยยังมิได้รับความยินยอมจากผู้ใช้อำนาจปกครองที่มีอำนาจกระทำการแทนผู้เยาว์
					หรือผู้อนุบาล หรือผู้พิทักษ์ตามแต่กรณี ดังนี้ บริษัท มีรีไฟน์ จำกัด
					จะดำเนินการลบทำลายข้อมูลส่วนบุคคลนั้นโดยเร็วหาก บริษัท มีรีไฟน์ จำกัด
					ไม่มีเหตุอันชอบด้วยกฎหมายประการอื่นนอกเหนือจากความยินยอมในการเก็บรวบรวม
					ใช้หรือเปิดเผยข้อมูลดังกล่าว
				</p>
				<br />
				<h3>วัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล</h3>
				<p>
					บริษัท มีรีไฟน์ จำกัด
					ดำเนินการเก็บรวบรวมข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์หลายประการ
					ซึ่งขึ้นอยู่กับประเภทของผลิตภัณฑ์หรือบริการหรือกิจกรรมที่ท่านใช้บริการ
					ตลอดจนลักษณะความสัมพันธ์ของท่านกับ บริษัท มีรีไฟน์ จำกัด
					หรือข้อพิจารณาในแต่ละบริบทเป็นสำคัญ
					โดยวัตถุประสงค์ที่ระบุไว้ดังต่อไปนี้เป็นเพียงกรอบการใช้ข้อมูลส่วนบุคคลของ
					บริษัท มีรีไฟน์ จำกัด เป็นการทั่วไป ทั้งนี้
					เฉพาะวัตถุประสงค์ที่เกี่ยวข้องกับผลิตภัณฑ์หรือบริการที่ท่านใช้งานหรือมีความสัมพันธ์ด้วยเท่านั้นที่จะมีผลบังคับใช้กับข้อมูลของท่าน
				</p>
				<p>
					<ol>
						<li>
							เพื่อให้บริการและบริหารจัดการบริการของ บริษัท มีรีไฟน์ จำกัด
							ทั้งบริการภายใต้สัญญาที่มีต่อท่าน หรือตามพันธกิจของ บริษัท
							มีรีไฟน์ จำกัด
						</li>
						<li>เพื่อการดำเนินการทางธุรกรรมของ บริษัท มีรีไฟน์ จำกัด</li>
						<li>
							ควบคุมดูแล ใช้งาน ติดตาม
							ตรวจสอบและบริหารจัดการบริการเพื่ออำนวยความสะดวกและสอดคล้องกับความต้องการของท่าน
						</li>
						<li>
							เพื่อเก็บรักษาและปรับปรุงข้อมูลอันเกี่ยวกับท่าน
							รวมทั้งเอกสารที่มีการกล่าวอ้างถึงท่าน
						</li>
						<li>
							จัดทำบันทึกรายการการประมวลผลข้อมูลส่วนบุคคลตามที่กฎหมายกำหนด
						</li>
						<li>
							วิเคราะห์ข้อมูล รวมถึงแก้ไขปัญหาที่เกี่ยวกับบริการของ บริษัท
							มีรีไฟน์ จำกัด
						</li>
						<li>
							เพื่อดำเนินการตามที่จำเป็นในการบริหารจัดการภายในองค์กร
							รวมถึงการรับสมัครงาน การสรรหากรรมการหรือผู้ดำรงตำแหน่งต่าง ๆ
							การประเมินคุณสมบัติ
						</li>
						<li>
							ป้องกัน ตรวจจับ หลีกเลี่ยง และตรวจสอบการฉ้อโกง
							การละเมิดความปลอดภัย หรือการกระทำที่ต้องห้าม หรือผิดกฎหมาย
							และอาจเกิดความเสียหายต่อทั้งบริษัท มีรีไฟน์ จำกัด
							และเจ้าของข้อมูลส่วนบุคคล
						</li>
						<li>
							การยืนยันตัวตน
							พิสูจน์ตัวตนและตรวจสอบข้อมูลเมื่อท่านสมัครใช้บริการของ บริษัท
							มีรีไฟน์ จำกัด หรือติดต่อใช้บริการ หรือใช้สิทธิตามกฎหมาย
						</li>
						<li>ปรับปรุงและพัฒนาคุณภาพผลิตภัณฑ์และบริการให้ทันสมัย</li>
						<li>การประเมินและบริหารจัดการความเสี่ยง</li>
						<li>
							ส่งการแจ้งเตือน การยืนยันการทำคำสั่ง
							ติดต่อสื่อสารและแจ้งข่าวสารไปยังท่าน
						</li>
						<li>
							เพื่อจัดทำและส่งมอบเอกสารหรือข้อมูลที่มีความเกี่ยวข้องและจำเป็น
						</li>
						<li>
							ยืนยันตัวตน ป้องกันการสแปม หรือการกระทำที่ไม่ได้รับอนุญาต
							หรือผิดกฎหมาย
						</li>
						<li>
							ตรวจสอบว่าเจ้าของข้อมูลส่วนบุคคลเข้าถึงและใช้บริการของ บริษัท
							มีรีไฟน์ จำกัด อย่างไร ทั้งในภาพรวมและรายบุคคล
							และเพื่อวัตถุประสงค์ที่เกี่ยวกับการบริการ
						</li>
						<li>
							ดำเนินการตามที่จำเป็นเพื่อปฏิบัติตามหน้าที่ที่ บริษัท มีรีไฟน์
							จำกัด มีต่อหน่วยงานที่มีอำนาจควบคุมการบังคับใช้กฎหมาย
							หรือภาระผูกพันตามกฎหมายของ บริษัท มีรีไฟน์ จำกัด
						</li>
						<li>
							ดำเนินการตามที่จำเป็นเพื่อประโยชน์ที่ชอบด้วยกฎหมายของ บริษัท
							มีรีไฟน์ จำกัด หรือของบุคคลอื่น
							หรือของนิติบุคคลอื่นที่เกี่ยวข้องกับการการดำเนินการของ บริษัท
							มีรีไฟน์ จำกัด
						</li>
						<li>
							เพื่อการปฏิบัติตามกฎหมาย ประกาศ คำสั่งที่มีผลบังคับใช้
							หรือการดำเนินการเกี่ยวกับคดีความ
							การดำเนินการเกี่ยวกับข้อมูลตามหมายศาล
							รวมถึงการใช้สิทธิเกี่ยวกับข้อมูลของท่าน
						</li>
					</ol>
				</p>
				<br />
				<h3>
					ประเภทบุคคลที่ บริษัท มีรีไฟน์ จำกัด เปิดเผยข้อมูลส่วนบุคคลของท่าน
				</h3>
				<p>
					ภายใต้วัตถุประสงค์ที่ได้ระบุไว้ในข้อ 9 ข้างต้น บริษัท มีรีไฟน์ จำกัด
					อาจเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บุคคลดังต่อไปนี้ ทั้งนี้
					ประเภทของบุคคลผู้รับข้อมูลที่ระบุไว้ดังต่อไปนี้เป็นเพียงกรอบการเปิดเผยข้อมูลส่วนบุคคลของ
					บริษัท มีรีไฟน์ จำกัด เป็นการทั่วไป
					เฉพาะบุคคลผู้รับข้อมูลที่เกี่ยวข้องกับผลิตภัณฑ์หรือบริการที่ท่านใช้งานหรือมีความสัมพันธ์ด้วยเท่านั้นที่จะมีผลบังคับใช้
				</p>
				<table>
					<tr>
						<th>ประเภทบุคคลผู้รับข้อมูล</th>
						<th>รายละเอียด</th>
					</tr>
					<tr>
						<td style={{ width: 200 }}>
							หน่วยงานของรัฐหรือผู้มีอำนาจที่ บริษัท มีรีไฟน์ จำกัด
							ต้องเปิดเผยข้อมูลเพื่อวัตถุประสงค์ในการดำเนินการตามกฎหมายหรือวัตถุประสงค์สำคัญอื่น
						</td>
						<td>
							หน่วยงานผู้บังคับใช้กฎหมาย
							หรือมีอำนาจควบคุมกำกับดูแลหรือมีวัตถุประสงค์อื่นที่มีความสำคัญ
							เช่น คณะรัฐมนตรี รัฐมนตรีผู้รักษาการ กรมการปกครอง กรมสรรพากร
							สำนักงานตำรวจ ศาล สำนักงานอัยการ
							กระทรวงดิจิทัลเพื่อเศรษฐกิจและสังคม
							กองทุนเงินให้กู้ยืมเพื่อการศึกษา เป็นต้น
						</td>
					</tr>
					<tr>
						<td>
							คู่สัญญาซึ่งดำเนินการเกี่ยวกับสวัสดิการของผู้ปฏิบัติงานของ บริษัท
							มีรีไฟน์ จำกัด
						</td>
						<td>
							บุคคลภายนอกที่ บริษัท มีรีไฟน์ จำกัด
							จัดซื้อจัดจ้างให้ดำเนินการเกี่ยวกับสวัสดิการ เช่น บริษัทประกันภัย
							โรงพยาบาล บริษัทผู้จัดทำ Payroll ธนาคาร ผู้ให้บริการโทรศัพท์
							เป็นต้น
						</td>
					</tr>
					<tr>
						<td>พันธมิตรทางธุรกิจ</td>
						<td>
							บริษัท มีรีไฟน์ จำกัด อาจเปิดเผยข้อมูลของท่านแก่บุคคลที่ร่วมงานกับ
							บริษัท มีรีไฟน์ จำกัด เพื่อประโยชน์ในการให้บริการแก่ท่าน เช่น
							บริษัทประกันภัย บริษัทผู้ให้บริการด้านรับตรวจสอบอุบัติเหตุ
							ผู้ให้บริการที่ท่านติดต่อผ่านบริการของ บริษัท มีรีไฟน์ จำกัด
							เป็นต้น
						</td>
					</tr>
					<tr>
						<td>ผู้ให้บริการ</td>
						<td>
							บริษัท มีรีไฟน์ จำกัด อาจมอบหมายให้บุคคลอื่นเป็นผู้ให้บริการแทน
							หรือสนับสนุนการดำเนินการของ บริษัท มีรีไฟน์ จำกัด เช่น
							ผู้ให้บริการด้านการจัดเก็บข้อมูล (เช่น คลาวด์ โกดังเอกสาร)
							ผู้พัฒนาระบบ ซอฟต์แวร์ แอปพลิเคชัน เว็บไซต์
							ผู้ให้บริการจัดส่งเอกสาร ผู้ให้บริการด้านการชำระเงิน
							ผู้ให้บริการอินเทอร์เน็ต ผู้ให้บริการโทรศัพท์ ผู้ให้บริการด้าน
							Digital ID ผู้ให้บริการสื่อสังคมออนไลน์
							ผู้ให้บริการด้านการบริหารความเสี่ยง ที่ปรึกษาภายนอก
							ผู้ให้บริการขนส่ง เป็นต้น
						</td>
					</tr>
					<tr>
						<td>ผู้รับข้อมูลประเภทอื่น</td>
						<td>
							บริษัท มีรีไฟน์ จำกัด
							อาจเปิดเผยข้อมูลของท่านให้แก่บุคคลผู้รับข้อมูลประเภทอื่น เช่น
							ผู้ติดต่อ บริษัท มีรีไฟน์ จำกัด สมาชิกในครอบครัว โรงพยาบาล
							หรือหน่วยงานอื่น ๆ เป็นต้น ทั้งนี้
							เพื่อการดำเนินการเกี่ยวกับบริการของ บริษัท มีรีไฟน์ จำกัด{' '}
						</td>
					</tr>
				</table>
				<br />
				<h3>การส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ</h3>
				<p>
					ในบางกรณี บริษัท มีรีไฟน์ จำกัด
					อาจจำเป็นต้องส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังต่างประเทศเพื่อดำเนินการตามวัตถุประสงค์ในการให้บริการแก่ท่าน
					เช่น เพื่อส่งข้อมูลส่วนบุคคลไปยังระบบคลาวด์ (Cloud)
					ที่มีแพลตฟอร์มหรือเครื่องแม่ข่าย (Server) อยู่ต่างประเทศ (เช่น
					ประเทศสิงคโปร์ หรือสหรัฐอเมริกา เป็นต้น)
					เพื่อสนับสนุนระบบเทคโนโลยีสารสนเทศที่ตั้งอยู่นอกประเทศไทย ทั้งนี้
					ขึ้นอยู่กับบริการของ บริษัท มีรีไฟน์ จำกัด
					ที่ท่านใช้งานหรือมีส่วนเกี่ยวข้องเป็นรายกิจกรรม
				</p>
				<p>
					อย่างไรก็ตาม ในขณะที่จัดทำนโยบายฉบับนี้
					คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลยังมิได้มีประกาศกำหนดรายการประเทศปลายทางที่มีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอ
					ดังนี้ เมื่อ บริษัท มีรีไฟน์ จำกัด
					มีความจำเป็นต้องส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังประเทศปลายทาง
					บริษัท มีรีไฟน์ จำกัด
					จะดำเนินการเพื่อให้ข้อมูลส่วนบุคคลที่ส่งหรือโอนไปมีมาตรการคุ้มครองข้อมูลส่วนบุคคลอย่างเพียงพอตามมาตรฐานสากล
					หรือดำเนินการตามเงื่อนไขเพื่อให้สามารถส่งหรือโอนข้อมูลนั้นได้ตามกฎหมาย
					ได้แก่
				</p>
				<p>
					<ol>
						<li>
							เป็นการปฏิบัติตามกฎหมายที่กำหนดให้ บริษัท มีรีไฟน์ จำกัด
							ต้องส่งหรือโอนข้อมูลส่วนบุคคลไปต่างประเทศ
						</li>
						<li>
							ได้แจ้งให้ท่านทราบและได้รับความยินยอมจากท่านในกรณีที่ประเทศปลายทางมีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่ไม่เพียงพอ
							ทั้งนี้ตามประกาศรายชื่อประเทศที่คณะกรรมการคุ้มครองส่วนบุคคลประกาศกำหนด
						</li>
						<li>
							เป็นการจำเป็นเพื่อปฏิบัติตามสัญญาที่ท่านเป็นคู่สัญญากับ บริษัท
							มีรีไฟน์ จำกัด หรือเป็นการทำตามคำขอของท่านก่อนการเข้าทำสัญญานั้น
						</li>
						<li>
							เป็นการกระทำตามสัญญาของ บริษัท มีรีไฟน์ จำกัด
							กับบุคคลหรือนิติบุคคลอื่น เพื่อประโยชน์ของท่าน
						</li>
						<li>
							เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย
							หรือสุขภาพของท่านหรือของบุคคลอื่น
							เมื่อท่านไม่สามารถให้ความยินยอมในขณะนั้นได้
						</li>
						<li>เป็นการจำเป็นเพื่อดำเนินภารกิจเพื่อประโยชน์สาธารณะที่สำคัญ</li>
					</ol>
				</p>
				<br />
				<h3>ระยะเวลาในการเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน</h3>
				<p>
					บริษัท มีรีไฟน์ จำกัด
					จะเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้ในระยะเวลาเท่าที่ข้อมูลนั้นยังมีความจำเป็นตามวัตถุประสงค์ในการเก็บรวบรวมข้อมูลเท่านั้น
					ตามรายละเอียดที่ได้กำหนดไว้ในนโยบาย ประกาศหรือตามกฎหมายที่เกี่ยวข้อง
					ทั้งนี้
					เมื่อพ้นระยะเวลาและข้อมูลส่วนบุคคลของท่านสิ้นความจำเป็นตามวัตถุประสงค์ดังกล่าวแล้ว
					บริษัท มีรีไฟน์ จำกัด จะทำการลบ ทำลายข้อมูลส่วนบุคคลของท่าน
					หรือทำให้ข้อมูลส่วนบุคคลของท่านไม่สามารถระบุตัวตนได้ต่อไป
					ตามรูปแบบและมาตรฐานการลบทำลายข้อมูลส่วนบุคคลที่คณะกรรมการหรือกฎหมายจะได้ประกาศกำหนดหรือตามมาตรฐานสากล
					อย่างไรก็ดี ในกรณีที่มีข้อพิพาท
					การใช้สิทธิหรือคดีความอันเกี่ยวข้องกับข้อมูลส่วนบุคคลของท่าน บริษัท
					มีรีไฟน์ จำกัด
					ขอสงวนสิทธิในการเก็บรักษาข้อมูลนั้นต่อไปจนกว่าข้อพิพาทนั้นจะได้มีคำสั่งหรือคำพิพากษาถึงที่สุด
				</p>
				<br />
				<h3>การให้บริการโดยบุคคลที่สามหรือผู้ให้บริการช่วง</h3>
				<p>
					บริษัท มีรีไฟน์ จำกัด อาจมีการมอบหมายหรือจัดซื้อจัดจ้างบุคคลที่สาม
					(ผู้ประมวลผลข้อมูลส่วนบุคคล)
					ให้ทำการประมวลผลข้อมูลส่วนบุคคลแทนหรือในนามของ บริษัท มีรีไฟน์ จำกัด
					ซึ่งบุคคลที่สามดังกล่าวอาจเสนอบริการในลักษณะต่าง ๆ เช่น การเป็นผู้ดูแล
					(Hosting) รับงานบริการช่วง (Outsourcing) หรือเป็นผู้ให้บริการคลาวด์
					(Cloud computing service/provider)
					หรือเป็นงานในลักษณะการจ้างทำของในรูปแบบอื่น
				</p>
				<p>
					การมอบหมายให้บุคคลที่สามทำการประมวลผลข้อมูลส่วนบุคคลในฐานะผู้ประมวลผลข้อมูลส่วนบุคคลนั้น
					บริษัท มีรีไฟน์ จำกัด จะจัดให้มีข้อตกลงระบุสิทธิและหน้าที่ของ บริษัท
					มีรีไฟน์ จำกัด ในฐานะผู้ควบคุมข้อมูลส่วนบุคคลและของบุคคลที่ บริษัท
					มีรีไฟน์ จำกัด มอบหมายในฐานะผู้ประมวลผลข้อมูลส่วนบุคคล
					ซึ่งรวมถึงกำหนดรายละเอียดประเภทข้อมูลส่วนบุคคลที่ บริษัท มีรีไฟน์
					จำกัด มอบหมายให้ประมวลผล รวมถึงวัตถุประสงค์
					ขอบเขตในการประมวลผลข้อมูลส่วนบุคคลและข้อตกลงอื่น ๆ ที่เกี่ยวข้อง
					ซึ่งผู้ประมวลผลข้อมูลส่วนบุคคลมีหน้าที่ประมวลผลข้อมูลส่วนบุคคลตามขอบเขตที่ระบุในข้อตกลงและตามคำสั่งของ
					บริษัท มีรีไฟน์ จำกัด
					เท่านั้นโดยไม่สามารถประมวลผลเพื่อวัตถุประสงค์อื่นได้
				</p>
				<p>
					ในกรณีที่ผู้ประมวลผลข้อมูลส่วนบุคคลมีการมอบหมายผู้ให้บริการช่วง
					(ผู้ประมวลผลช่วง)
					เพื่อทำการประมวลผลข้อมูลส่วนบุคคลแทนหรือในนามของผู้ประมวลผลข้อมูลส่วนบุคคล
					ดังนี้ บริษัท มีรีไฟน์ จำกัด
					จะกำกับให้ผู้ประมวลผลข้อมูลส่วนบุคคลจัดให้มีเอกสารข้อตกลงระหว่างผู้ประมวลผลข้อมูลส่วนบุคคลกับผู้ประมวลผลช่วง
					ในรูปแบบและมาตรฐานที่ไม่ต่ำกว่าข้อตกลงระหว่าง บริษัท มีรีไฟน์ จำกัด
					กับผู้ประมวลผลข้อมูลส่วนบุคคล
				</p>
				<br />
				<h3>การรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล</h3>
				<p>
					บริษัท มีรีไฟน์ จำกัด มีมาตรการปกป้องข้อมูลส่วนบุคคล
					โดยการจำกัดสิทธิ์การเข้าถึงข้อมูลส่วนบุคคลให้สามารถเข้าถึงได้โดยเจ้าหน้าที่เฉพาะรายหรือบุคคลที่มีอำนาจหน้าที่หรือได้รับมอบหมายที่มีความจำเป็นต้องใช้ข้อมูลดังกล่าวตามวัตถุประสงค์ที่ได้แจ้งเจ้าของข้อมูลส่วนบุคคลไว้แล้วเท่านั้น
					ซึ่งบุคคลดังกล่าวจะต้องยึดมั่นและปฏิบัติตามมาตรการปกป้องข้อมูลส่วนบุคคลของ
					บริษัท มีรีไฟน์ จำกัด อย่างเคร่งครัด
					ตลอดจนมีหน้าที่รักษาความลับของข้อมูลส่วนบุคคลที่ตนเองรับรู้จากการปฏิบัติการตามอำนาจหน้าที่
					โดย บริษัท มีรีไฟน์ จำกัด
					มีมาตรการรักษาความปลอดภัยข้อมูลทั้งในเชิงองค์กรหรือเชิงเทคนิคที่ได้มาตรฐานสากล
					และเป็นไปตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลประกาศกำหนด
				</p>
				<p>
					นอกจากนี้ เมื่อ บริษัท มีรีไฟน์ จำกัด มีการส่ง
					โอนหรือเปิดเผยข้อมูลส่วนบุคคลแก่บุคคลที่สาม
					ไม่ว่าเพื่อการให้บริการตามพันธกิจ ตามสัญญา หรือข้อตกลงในรูปแบบอื่น
					บริษัท มีรีไฟน์ จำกัด
					จะกำหนดมาตรการรักษาความปลอดภัยข้อมูลส่วนบุคคลและการรักษาความลับที่เหมาะสมและเป็นไปตามที่กฎหมายกำหนด
					เพื่อยืนยันว่าข้อมูลส่วนบุคคลที่ บริษัท มีรีไฟน์ จำกัด
					เก็บรวบรวมจะมีความมั่นคงปลอดภัยอยู่เสมอ
				</p>
				<br />
				<h3>การเชื่อมต่อเว็บไซต์หรือบริการภายนอก</h3>
				<p>
					บริการของ บริษัท มีรีไฟน์ จำกัด
					อาจมีการเชื่อมต่อไปยังเว็บไซต์หรือบริการของบุคคลที่สาม
					ซึ่งเว็บไซต์หรือบริการดังกล่าวอาจมีการประกาศนโยบายการคุ้มครองข้อมูลส่วนบุคคลที่มีเนื้อหาสาระแตกต่างจากนโยบายนี้
					บริษัท มีรีไฟน์ จำกัด
					ขอแนะนำให้ท่านศึกษานโยบายการคุ้มครองข้อมูลส่วนบุคคลของเว็บไซต์หรือบริการนั้น
					ๆ เพื่อทราบในรายละเอียดก่อนการเข้าใช้งาน ทั้งนี้ บริษัท มีรีไฟน์ จำกัด
					ไม่มีความเกี่ยวข้องและไม่มีอำนาจควบคุมถึงมาตรการคุ้มครองข้อมูลส่วนบุคคลของเว็บไซต์หรือบริการดังกล่าวและไม่สามารถรับผิดชอบต่อเนื้อหา
					นโยบาย ความเสียหาย
					หรือการกระทำอันเกิดจากเว็บไซต์หรือบริการของบุคคลที่สาม
				</p>
				<br />
				<h3>เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</h3>
				<p>
					บริษัท มีรีไฟน์ จำกัด
					ได้แต่งตั้งเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลเพื่อทำหน้าที่ตรวจสอบ
					กำกับและให้คำแนะนำในการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
					รวมถึงการประสานงานและให้ความร่วมมือกับสำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล
					เพื่อให้สอดคล้องตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
				</p>
				<br />
				<h3>สิทธิของท่านตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562</h3>
				<p>
					พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
					ได้กำหนดสิทธิของเจ้าของข้อมูลส่วนบุคคลไว้หลายประการ ทั้งนี้
					สิทธิดังกล่าวจะเริ่มมีผลบังคับใช้เมื่อกฎหมายในส่วนของสิทธินี้มีผลใช้บังคับ
					โดยรายละเอียดของสิทธิต่าง ๆ ประกอบด้วย
				</p>
				<p>
					1) <strong>สิทธิในการขอเข้าถึงข้อมูลส่วนบุคคล</strong>{' '}
					ท่านมีสิทธิขอเข้าถึง รับสำเนาและขอให้เปิดเผยที่มาของข้อมูลส่วนบุคคลที่
					บริษัท มีรีไฟน์ จำกัด เก็บรวบรวมไว้โดยปราศจากความยินยอมของท่าน
					เว้นแต่กรณีที่ บริษัท มีรีไฟน์ จำกัด
					มีสิทธิปฏิเสธคำขอของท่านด้วยเหตุตามกฎหมายหรือคำสั่งศาล
					หรือกรณีที่การใช้สิทธิของท่านจะมีผลกระทบที่อาจก่อให้เกิดความเสียหายต่อสิทธิและเสรีภาพของบุคคลอื่น
				</p>
				<p>
					2){' '}
					<strong>
						สิทธิในการขอแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง สมบูรณ์และเป็นปัจจุบัน
					</strong>{' '}
					หากท่านพบว่าข้อมูลส่วนบุคคลของท่านไม่ถูกต้อง
					ไม่ครบถ้วนหรือไม่เป็นปัจจุบัน
					ท่านมีสิทธิขอให้แก้ไขเพื่อให้มีความถูกต้อง เป็นปัจจุบัน สมบูรณ์
					และไม่ก่อให้เกิดความเข้าใจผิดได้
				</p>
				<p>
					3) <strong>สิทธิในการลบหรือทำลายข้อมูลส่วนบุคคล </strong>{' '}
					ท่านมีสิทธิขอให้ บริษัท มีรีไฟน์ จำกัด
					ลบหรือทำลายข้อมูลส่วนบุคคลของท่าน
					หรือทำให้ข้อมูลส่วนบุคคลของท่านไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลได้ต่อไป
					ทั้งนี้
					การใช้สิทธิลบหรือทำลายข้อมูลส่วนบุคคลนี้จะต้องอยู่ภายใต้เงื่อนไขตามที่กฎหมายกำหนด{' '}
				</p>
				<p>
					4) <strong>สิทธิในการขอให้ระงับการใช้ข้อมูลส่วนบุคคล </strong>{' '}
					ท่านมีสิทธิขอให้ระงับการใช้ข้อมูลส่วนบุคคลของท่าน ทั้งนี้
					ในกรณีดังต่อไปนี้
					<ul style={{ listStyleType: 'none' }}>
						<li>
							ก) เมื่ออยู่ในช่วงเวลาที่ บริษัท มีรีไฟน์ จำกัด
							ทำการตรวจสอบตามคำร้องขอของเจ้าของข้อมูลส่วนบุคคลให้แก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง
							สมบูรณ์และเป็นปัจจุบัน
						</li>
						<li>
							ข) ข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลถูกเก็บรวบรวม
							ใช้หรือเปิดเผยโดยมิชอบด้วยกฎหมาย
						</li>
						<li>
							ค)
							เมื่อข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลหมดความจำเป็นในการเก็บรักษาไว้ตามวัตถุประสงค์ที่
							บริษัท มีรีไฟน์ จำกัด ได้แจ้งในการเก็บรวบรวม
							แต่เจ้าของข้อมูลส่วนบุคคลประสงค์ให้ บริษัท มีรีไฟน์ จำกัด
							เก็บรักษาข้อมูลนั้นต่อไปเพื่อประกอบการใช้สิทธิตามกฎหมาย
						</li>
						<li>
							ง) เมื่ออยู่ในช่วงเวลาที่ บริษัท มีรีไฟน์ จำกัด
							กำลังพิสูจน์ถึงเหตุอันชอบด้วยกฎหมายในการเก็บรวบรวมข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล
							หรือตรวจสอบความจำเป็นในการเก็บรวบรวม ใช้
							หรือเปิดเผยข้อมูลส่วนบุคคลเพื่อประโยชน์สาธารณะ
							อันเนื่องมาจากการที่เจ้าของข้อมูลส่วนบุคคลได้ใช้สิทธิคัดค้านการเก็บรวบรวม
							ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
						</li>
					</ul>
				</p>
				<p>
					5) <strong>สิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคล</strong>{' '}
					ท่านมีสิทธิคัดค้านการเก็บรวบรวม
					ใช้หรือเปิดเผยข้อมูลส่วนบุคคลที่เกี่ยวกับท่าน เว้นแต่กรณีที่ บริษัท
					มีรีไฟน์ จำกัด มีเหตุในการปฏิเสธคำขอโดยชอบด้วยกฎหมาย (เช่น บริษัท
					มีรีไฟน์ จำกัด สามารถแสดงให้เห็นว่าการเก็บรวบรวม ใช้
					หรือเปิดเผยข้อมูลส่วนบุคคลของท่านมีเหตุอันชอบด้วยกฎหมายยิ่งกว่า
					หรือเพื่อการก่อตั้งสิทธิเรียกร้องตามกฎหมาย
					การปฏิบัติตามหรือการใช้สิทธิเรียกร้องทางกฎหมาย)
				</p>
				<p>
					6) <strong>สิทธิในการขอถอนความยินยอม </strong>{' '}
					ในกรณีที่ท่านได้ให้ความยินยอมแก่ บริษัท มีรีไฟน์ จำกัด ในการเก็บรวบรวม
					ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
					(ไม่ว่าความยินยอมนั้นจะได้ให้ไว้ก่อนหรือหลังพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
					พ.ศ. 2562 มีผลใช้บังคับ)
					ท่านมีสิทธิถอนความยินยอมเมื่อใดก็ได้ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลของท่านถูกเก็บรักษาโดย
					บริษัท มีรีไฟน์ จำกัด เว้นแต่มีข้อจำกัดสิทธิโดยกฎหมายให้ บริษัท
					มีรีไฟน์ จำกัด
					จำเป็นต้องเก็บรักษาข้อมูลต่อไปหรือยังคงมีสัญญาระหว่างท่านกับ บริษัท
					มีรีไฟน์ จำกัด ที่ให้ประโยชน์แก่ท่านอยู่
				</p>
				<p>
					7) <strong>สิทธิในการขอรับ ส่งหรือโอนข้อมูลส่วนบุคคล </strong>{' '}
					ท่านมีสิทธิในการขอรับข้อมูลส่วนบุคคลของท่านจาก บริษัท มีรีไฟน์ จำกัด
					ในรูปแบบที่สามารถอ่านหรือใช้งานโดยทั่วไปได้ด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติและสามารถใช้หรือเปิดเผยข้อมูลส่วนบุคคลได้โดยวิธีการอัตโนมัติ
					รวมถึงอาจขอให้ บริษัท มีรีไฟน์ จำกัด
					ส่งหรือโอนข้อมูลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลรายอื่น
					ทั้งนี้ การใช้สิทธินี้จะต้องอยู่ภายใต้เงื่อนไขตามที่กฎหมายกำหนด
				</p>
				<br />
				<h3>โทษของการไม่ปฏิบัติตามนโยบายการคุ้มครองข้อมูลส่วนบุคคล</h3>
				<p>
					การไม่ปฏิบัติตามนโยบายอาจมีผลเป็นความผิดและถูกลงโทษตามกฎเกณฑ์ของ
					บริษัท มีรีไฟน์ จำกัด (สำหรับเจ้าหน้าที่หรือผู้ปฏิบัติงานของ บริษัท
					มีรีไฟน์ จำกัด ) หรือตามข้อตกลงการประมวลผลข้อมูลส่วนบุคคล
					(สำหรับผู้ประมวลผลข้อมูลส่วนบุคคล) ทั้งนี้
					ตามแต่กรณีและความสัมพันธ์ที่ท่านมีต่อ บริษัท มีรีไฟน์ จำกัด
					และอาจได้รับโทษตามที่กำหนดโดยพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.
					2562 รวมทั้งกฎหมายลำดับรอง กฎ ระเบียบ คำสั่งที่เกี่ยวข้อง
				</p>
				<br />
				<h3>การร้องเรียนต่อหน่วยงานผู้มีอำนาจกำกับดูแล</h3>
				<p>
					ในกรณีที่ท่านพบว่า บริษัท มีรีไฟน์ จำกัด
					มิได้ปฏิบัติตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
					ท่านมีสิทธิร้องเรียนไปยังคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล
					หรือหน่วยงานที่มีอำนาจกำกับดูแลที่ได้รับการแต่งตั้งโดยคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลหรือตามกฎหมาย
					ทั้งนี้ ก่อนการร้องเรียนดังกล่าว บริษัท มีรีไฟน์ จำกัด
					ขอให้ท่านโปรดติดต่อมายัง บริษัท มีรีไฟน์ จำกัด เพื่อให้ บริษัท
					มีรีไฟน์ จำกัด มีโอกาสได้รับทราบข้อเท็จจริงและได้ชี้แจงในประเด็นต่าง ๆ
					รวมถึงจัดการแก้ไขข้อกังวลของท่านก่อนในโอกาสแรก
				</p>
				<br />
				<h3>การปรับปรุงแก้ไขนโยบายการคุ้มครองข้อมูลส่วนบุคคล</h3>
				<p>
					บริษัท มีรีไฟน์ จำกัด อาจพิจารณาปรับปรุง
					แก้ไขหรือเปลี่ยนแปลงนโยบายนี้ตามที่เห็นสมควร
					และจะทำการแจ้งให้ท่านทราบผ่านช่องทางเว็บไซต์{' '}
					<a href="https://merefine.io" style={{ color: '#3d80fa' }}>
						{' '}
						merefine.io{' '}
					</a>{' '}
					โดยมีวันที่มีผลบังคับใช้ของแต่ละฉบับแก้ไขกำกับอยู่ อย่างไรก็ดี บริษัท
					มีรีไฟน์ จำกัด
					ขอแนะนำให้ท่านโปรดตรวจสอบเพื่อรับทราบนโยบายฉบับใหม่อย่างสม่ำเสมอ
					ผ่านแอปพลิเคชัน หรือช่องทางเฉพาะกิจกรรมที่ บริษัท มีรีไฟน์ จำกัด
					ดำเนินการ โดยเฉพาะก่อนที่ท่านจะทำการเปิดเผยข้อมูลส่วนบุคคลแก่ บริษัท
					มีรีไฟน์ จำกัด
				</p>
				<p>
					การเข้าใช้งานผลิตภัณฑ์หรือบริการของ บริษัท มีรีไฟน์ จำกัด
					ภายหลังการบังคับใช้นโยบายใหม่
					ถือเป็นการรับทราบตามข้อตกลงในนโยบายใหม่แล้ว ทั้งนี้
					โปรดหยุดการเข้าใช้งานหากท่านไม่เห็นด้วยกับรายละเอียดในนโยบายฉบับนี้และโปรดติดต่อมายัง
					บริษัท มีรีไฟน์ จำกัด เพื่อชี้แจงข้อเท็จจริงต่อไป
				</p>
				<br />
				<h3>การติดต่อสอบถามหรือใช้สิทธิ</h3>
				<p>
					หากท่านมีข้อสงสัย ข้อเสนอแนะหรือข้อกังวลเกี่ยวกับการเก็บรวบรวม ใช้
					และเปิดเผยข้อมูลส่วนบุคคลของ บริษัท มีรีไฟน์ จำกัด
					หรือเกี่ยวกับนโยบายนี้
					หรือท่านต้องการใช้สิทธิตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
					ท่านสามารถติดต่อสอบถามได้ที่
					<br />
					<br />
					1) <strong>ผู้ควบคุมข้อมูลส่วนบุคคล (Data Controller)</strong>
					<ul style={{ listStyleType: 'none' }}>
						<li>- ชื่อ: บริษัท มีรีไฟน์ จำกัด</li>
						<li>
							- สถานที่ติดต่อ: เลขที่ 369/105 หมู่ 2 ตำบลสุรศักดิ์ อำเภอศรีราชา
							จังหวัดชลบุรี 20110
						</li>
						<li>- ช่องทางการติดต่อ: contact@merefine.io</li>
						<li>- Call Center : 065 - 2237855</li>
					</ul>
					<br />
					2){' '}
					<strong>
						เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data Protection Officer: DPO)
					</strong>
					<ul style={{ listStyleType: 'none' }}>
						<li>
							- สถานที่ติดต่อ: เลขที่ 369/105 หมู่ 2 ตำบลสุรศักดิ์ อำเภอศรีราชา
							จังหวัดชลบุรี 20110
						</li>
						<li>- ช่องทางการติดต่อ: contact@merefine.io</li>
					</ul>
				</p>
			</div>
		</div>
	)
}
export default React.memo(PrivacyPolicy)
