import React, { Component } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import CardObjevtive from '../CardObjevtive/CardObjevtive'
import ClaimingImg from '../../static/image/1_claim.svg'
import EasyImg from '../../static/image/2_easy.svg'
import OrganizationImg from '../../static/image/3_organiztion.svg'
import './style.css'

const objectiveList: Array<Object> = [
	{
		topic: 'Fast to claim',
		img: ClaimingImg,
		imgWidth: 350,
		describe:
			'The application have an intelligent queue to find the surveyor that most nearest to the accident place.',
	},
	{
		topic: 'Easy to use',
		img: EasyImg,
		imgWidth: 250,
		describe:
			'Beginner users can use or understand the application quickly. The application was design friendly to all user.',
	},
	{
		topic: 'Support the insurance organization',
		img: OrganizationImg,
		imgWidth: 250,
		describe:
			'The application widely used in many organization to support them all about car claiming.',
	},
]

export default class Objective extends Component {
	componentDidMount() {
		AOS.init({ once: true, delay: 100, duration: 800 })
	}

	render() {
		const cardlist: any = objectiveList.map((obj: any, index: number) => {
			return (
				<div
					key={index}
					style={{ display: 'flex', margin: '0' }}
					data-aos="flip-left"
				>
					{index !== 0 ? <hr className="objective-hr" /> : null}
					<CardObjevtive
						topic={obj.topic}
						img={obj.img}
						imgWidth={obj.imgWidth}
						describe={obj.describe}
					/>
				</div>
			)
		})

		return (
			<div className="objective-wrapper">
				<h1>The one place of collects claims platform</h1>
				<p>
					Merefine provides application for support an insurance organization.
					The application have tool to quick find surveyor, beginner user can
					easy to use and application response to surveyor need.
				</p>
				<div className="objective-card-wrapper">{cardlist}</div>
			</div>
		)
	}
}
