import React, { Component } from 'react'
import ThreeGlobe from 'three-globe'
import './style.css'
import countries from './globe-data-min.json'
import cities from './cities.json'

import { WebGLRenderer, Scene } from 'three'
import {
	PerspectiveCamera,
	AmbientLight,
	DirectionalLight,
	Color,
	Fog,
} from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default class Globe extends Component {
	componentDidMount() {
		let mouseX = 0
		let mouseY = 0
		let windowHalfX = window.innerWidth / 2
		// let windowHalfY = window.innerHeight / 2
		var Globe
		const scene = new Scene()
		const camera = new PerspectiveCamera()
		let controls: any
		let renderer: any

		const N = 30

		const arcAlts = [0.1, 0.2, 0.25]
		const flights = [...new Array(N)].reduce((result = [], n, index) => {
			const startC = Math.floor(Math.random() * 29)
			const endC = Math.floor(Math.random() * 29)
			const x = parseFloat(cities[startC].lat) - parseFloat(cities[endC].lat)
			const y = parseFloat(cities[startC].lng) - parseFloat(cities[endC].lng)

			const dis = Math.sqrt(x * x + y * y)

			if (dis > 90) return result

			result.push({
				startLat: cities[startC].lat,
				startLng: cities[startC].lng,
				endLat: cities[endC].lat,
				endLng: cities[endC].lng,
				status: Math.random() * 4 < 3,
				order: Math.random() * 10 > 3 ? index : index + 0.5,
				arcAlt: arcAlts[Math.floor(Math.random() * arcAlts.length)],
			})

			return result
		}, [])

		const init = () => {
			// Initialize renderer
			const width = window.innerWidth / 2

			renderer = new WebGLRenderer({ alpha: true })
			renderer.setPixelRatio(window.devicePixelRatio)
			renderer.setSize(width, window.innerHeight)
			renderer.setClearColor(0x000000, 0)
			//@ts-ignore
			document.getElementById('globeViz').appendChild(renderer.domElement)
			// renderer.outputEncoding = THREE.sRGBEncoding;

			// Initialize scene, light
			scene.add(new AmbientLight(0xbbbbbb, 0.8))
			// scene.background = new Color(0x040d21)

			// Initialize camera, light
			camera.aspect = width / window.innerHeight
			camera.updateProjectionMatrix()

			var dLight = new DirectionalLight(0xffffff, 0.2)
			dLight.position.set(-800, 2000, 400)
			camera.add(dLight)

			var dLight1 = new DirectionalLight(0xffffff, 1)
			dLight1.position.set(-200, 500, 200)
			camera.add(dLight1)

			// var dLight2 = new PointLight(0x3d80fa, 0.5)
			// dLight2.position.set(-200, 500, 200)
			// camera.add(dLight2)

			camera.position.z = 325
			camera.position.x = 0
			camera.position.y = 0

			scene.add(camera)

			// Additional effects
			scene.fog = new Fog(0x535ef3, 400, 2000)

			// Helpers
			// const axesHelper = new AxesHelper(800);
			// scene.add(axesHelper);
			// var helper = new DirectionalLightHelper(dLight);
			// scene.add(helper);
			// var helperCamera = new CameraHelper(dLight.shadow.camera);
			// scene.add(helperCamera);

			// Initialize controls
			controls = new OrbitControls(camera, renderer.domElement)
			controls.enableDamping = true
			// controls.dynamicDampingFactor = 0.01;
			controls.enablePan = false
			controls.minDistance = 325
			controls.maxDistance = 325
			controls.rotateSpeed = 0.8
			controls.zoomSpeed = 1
			controls.autoRotate = true

			controls.minPolarAngle = Math.PI / 3.5
			controls.maxPolarAngle = Math.PI - Math.PI / 3
		}

		// SECTION Globe
		function initGlobe() {
			// Initialize the Globe
			Globe = new ThreeGlobe()
				// .globeImageUrl(EarthDarkSkin)
				.arcsData(flights)
				.arcColor((e: any) => {
					return e.status ? 'blue' : '#FF4000'
				})
				.arcAltitude((e: any) => {
					return e.arcAlt
				})
				.arcStroke((e: any) => {
					return e.status ? 0.8 : 0.5
				})
				.arcDashLength(0.9)
				.arcDashGap(4)
				.arcDashAnimateTime(1000)
				.arcsTransitionDuration(500)
				.arcDashInitialGap((e: any) => e.order * 1)
				.hexPolygonsData(countries.features)
				.hexPolygonResolution(3)
				.hexPolygonColor(() => '#3d80fa')
				.hexPolygonMargin(0.7)
				.showAtmosphere(false)

			Globe.rotateY(-Math.PI * (5 / 9))
			Globe.rotateZ(-Math.PI / 6)
			const globeMaterial = Globe.globeMaterial()
			globeMaterial.opacity = 0.2
			globeMaterial.color = new Color(0xffffff)
			// globeMaterial.emissive = new Color(0xffffff)
			// globeMaterial.emissiveIntensity = 0.1
			// globeMaterial.shininess = 0.7
			// NOTE Cool stuff
			// globeMaterial.wireframe = true

			// Initialize the glow
			// var options = {
			// 	backside: true,
			// 	color: '#3a228a',
			// 	size: 100 * 0.25,
			// 	power: 6,
			// 	coefficient: 0.3,
			// }
			// var glowMesh = createGlowMesh(new SphereGeometry(100, 75, 75), options)
			// Globe.add(glowMesh)
			scene.add(Globe)
		}

		const animate = () => {
			camera.position.x +=
				Math.abs(mouseX) <= windowHalfX / 2
					? (mouseX / 2 - camera.position.x) * 0.005
					: 0
			camera.position.y += (-mouseY / 2 - camera.position.y) * 0.005
			camera.lookAt(scene.position)
			controls.update()
			renderer.render(scene, camera)
			requestAnimationFrame(animate)
		}
		if (window.innerWidth < 1024) return
		init()
		initGlobe()
		animate()
	}

	render() {
		if (window.innerWidth < 1024) return <></>

		return (
			<div className="globe-wrapper">
				<div id="globeViz"></div>
			</div>
		)
	}
}
