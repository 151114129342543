import './style.css'

import React from 'react'

import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Contact = () => {
	return (
		<div className="contact-wrapper">
			<div className="contact">
				<div className="contact-address">
					<h1>Contact Us</h1>
					<hr className="contact-hr" />
					<br />
					<p>
						369/105 Moo.2 Si Racha-Nong Yai Bu Rd,Tambon Surasak, Si Racha
						District, Chon Buri 20110
					</p>
					<div className="contact-link">
						<a href="mailto:contact@merefine.io">
							<FontAwesomeIcon icon={faEnvelope} size="lg" />{' '}
							contact@merefine.io
						</a>
					</div>
					<div className="contact-link">
						<a href="tel:+66652237855">
							<FontAwesomeIcon icon={faPhoneSquareAlt} size="lg" /> +66 (0)6
							5223 7855
						</a>
					</div>
					<div className="contact-link">
						<a href="https://www.linkedin.com/company/merefine-co-ltd">
							<FontAwesomeIcon icon={faLinkedin} size="lg" /> /merefine-co-ltd
						</a>
					</div>
				</div>
				<iframe
					title="address-map"
					className="google-map-iframe"
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2449.077431186127!2d100.94110204390181!3d13.152416022545001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3102b78a8cd2c2ef%3A0x99689355a17e1f9f!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4oeC4teC4o-C4teC5hOC4n-C4meC5jCDguIjguLPguIHguLHguJQ!5e0!3m2!1sth!2sth!4v1595903845496!5m2!1sth!2sth"
					width="100%"
					height="500px"
					frameBorder="0"
					allowFullScreen={true}
					aria-hidden="false"
					tabIndex={0}
				></iframe>
			</div>
		</div>
	)
}
export default React.memo(Contact)
