import React from 'react';

const Policy = () => {
	return (
		<div className="policy-wrapper">
			<div
				style={{
					width: '210mm',
					marginLeft: 'auto',
					marginRight: 'auto',
					marginTop: '50px',
				}}
			>
				<h1>Privacy Policy</h1>
				<br />
				<br />
				<h3>Privacy Policy</h3>
				<p>
					This extension is provided by Merefine at no cost and is intended for
					use as is. This page is used to inform visitors regarding our policies
					with the collection, use, and disclosure of Personal Information if
					anyone decided to use our Service. If you choose to use our Service,
					then you agree to the collection and use of information in relation to
					this policy. The Personal Information that we collect is used for
					providing and improving the Service. We will not use or share your
					information with anyone except as described in this Privacy Policy.
					The terms used in this Privacy Policy have the same meanings as in our
					Terms and Conditions, which is accessible at Merefine unless otherwise
					defined in this Privacy Policy.
				</p>
				<br />
				<h3>Information gathering and usage</h3>
				<p>
					When sign in for Merefine extension we ask for username and password.
					Your information(only token from sign in) will keep in local storage,
					for any request to get information from only compatible website to
					automatically fill out input.
				</p>
				<br />
				<h3>Cookies</h3>
				<p>This Service does not use these “cookies” explicitly.</p>

				<br />
				<h3>Ad servers</h3>
				<p>
					We do not partner with or have special relationships with any ad
					server companies.
				</p>

				<br />
				<h3>Security</h3>
				<p>
					We value your trust in providing us your Personal Information, thus we
					are striving to use commercially acceptable means of protecting it.
					All data and information transmitted with Service is secured by SSL
					protocol.
				</p>

				<br />
				<h3>Children’s Privacy</h3>
				<p>
					These Services do not address anyone under the age of 13. We do not
					knowingly collect personally identifiable information from children
					under 13. In the case we discover that a child under 13 has provided
					us with personal information, we immediately delete this from our
					servers. If you are a parent or guardian and you are aware that your
					child has provided us with personal information, please contact us so
					that we will be able to do necessary actions.
				</p>

				<br />
				<h3>Ad servers</h3>
				<p>
					We do not partner with or have special relationships with any ad
					server companies.
				</p>

				<br />
				<h3>Changes to This Privacy Policy</h3>
				<p>
					We may update our Privacy Policy from time to time. Thus, you are
					advised to review this page periodically for any changes. We will
					notify you of any changes by posting the new Privacy Policy on this
					page. This policy is effective as of 2020-08-09
				</p>

				<br />
				<h3>Contact Us</h3>
				<p>
					If you have any questions or suggestions about our Privacy Policy, do
					not hesitate to contact our support team at: Merefine co.ltd, 369/105
					,moo2 Surasak, Sriracha, Chonburi - 20110. Email address:
					:contact@merefine.io
				</p>
			</div>
		</div>
	)
}
export default React.memo(Policy)
