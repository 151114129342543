// import Slider from 'react-slick'
import './style.css'

import React, { Component } from 'react'

export default class Client extends Component {
	state = {
		imageList: [],
		settings: {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 2,
			slidesToScroll: 2,
			initialSlide: 0,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						infinite: true,
						dots: true,
					},
				},
			],
			autoplay: true,
			autoplaySpeed: 3500,
		},
	}

	componentDidMount() {
		const imageList: any[] = []
		for (let i = 1; i <= 3; i++) {
			imageList.push(
				<img
					src={require(`../../static/image/c_${i}.png`)}
					alt={`client-${i + 1}`}
					className="client-image"
					key={i}
				/>
			)
		}
		this.setState({ imageList: imageList })
	}

	render() {
		return (
			<div className="client-wrapper">
				<h1 className="client-header">Our Clients</h1>
				<div className="client-img-wrapper">
					{this.state.imageList}
					<div className="client-img-hue"></div>
				</div>

				{/* <div className="slick-wrapper"> */}
				{/* <Slider {...this.state.settings}>{this.state.imageList}</Slider> */}
				{/* </div> */}
			</div>
		)
	}
}
