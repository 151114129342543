import React, { Component } from 'react'
import ScrollArrow from '../../static/image/scroll.png'
import { IReference } from '../../interfaces/reference'
import './style.css'

export default class Header extends Component<IReference> {
	render() {
		return (
			<div className="header-wrapper">
				<div className="header-area">
					<div className="header-left"></div>
					<div className="header-right">
						<div className="header-organizaiton-name">
							<h1>bring tech into your business</h1>
						</div>

						<div className="header-describe">
							<div className="tag">Auto insurance</div>
							<p>
								Provides a quick and easy way to get claim and not have to worry
								about accidents anymore.
							</p>
						</div>
					</div>
				</div>

				<div
					className="header-scroll-down-wrapper"
					onClick={() => this.props.executeScroll(this.props.refNavbar[2])}
				>
					<p>scroll down</p>
					<span className="header-scroll-down-space">
						<br />
						<br />
					</span>
					<img
						src={ScrollArrow}
						alt="scroll-down"
						height={20}
						style={{
							filter: ' sepia(100%) hue-rotate(90deg) saturate(400%)',
						}}
					/>
				</div>
			</div>
		)
	}
}
