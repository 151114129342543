import React, { Component } from 'react'
import Logo from '../../static/image/merefine_logo.svg'
import { IReference } from '../../interfaces/reference'
import './style.css'

export default class Navbar extends Component<IReference> {
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)
		window.addEventListener('resize', this.resize.bind(this))
		this.resize()
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
		window.removeEventListener('resize', this.resize.bind(this))
	}

	handleScroll = (event: any) => {
		const scrollY = window.scrollY
		const navbar: any = document.getElementsByClassName('navbar-wrapper')
		if (scrollY > 580) {
			// bottom
			navbar[0].style.top = '0'
		} else if (scrollY > 200 && scrollY < 580) {
			// hide
			// navbar[0].style.backgroundColor = 'transparent'
			navbar[0].style.top = '-100px'
		} else {
			// top
			// navbar[0].style.backgroundColor = 'transparent'
			navbar[0].style.top = '0'
		}
	}

	resize = () => this.setState({ hideNav: window.innerWidth <= 768 })

	toggleNavbar = () => {
		const menuNavbar: any = document.getElementsByClassName('li-wrapper')
		if (menuNavbar[0].classList) {
			menuNavbar[0].classList.toggle('active')
		} else {
			var classes = menuNavbar[0].className.split(' ')
			var i = classes.indexOf('active')

			if (i >= 0) classes.splice(i, 1)
			else classes.push('active')
			menuNavbar[0].className = classes.join(' ')
		}
	}

	changeMenu = (refId: number) => {
		if (refId !== 3 && window.innerWidth <= 768) this.toggleNavbar()
		this.props.executeScroll(this.props.refNavbar[refId])
	}

	render() {
		return (
			<div className="navbar">
				<div className="navbar-wrapper">
					<ul className="navbar-menu">
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<img
								className="navbar-image"
								src={Logo}
								alt="merefine-logo"
								width={60}
								height={40}
								onClick={() => this.changeMenu(3)}
							/>
							<div className="navbar-logo" onClick={() => this.changeMenu(3)}>
								Merefine
							</div>
						</div>
						<div className="menu">
							<div className="li-wrapper">
								<li onClick={() => this.changeMenu(1)}>Clients</li>

								<li onClick={() => this.changeMenu(0)}>Services & Products</li>
								<li onClick={() => this.changeMenu(2)}>Contact</li>
							</div>
							<svg
								onClick={() => this.toggleNavbar()}
								aria-hidden="true"
								focusable="false"
								data-prefix="fas"
								data-icon="bars"
								className="svg-inline--fa fa-bars fa-w-14 bar-menu-icon"
								role="img"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 448 512"
							>
								<path
									fill="currentColor"
									d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
								></path>
							</svg>
						</div>
					</ul>
				</div>
			</div>
		)
	}
}
