import React, { Component } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { IProductCard } from '../../interfaces/card'
import './style.css'
import { ParallaxBanner } from 'react-scroll-parallax'
export default class CardProduct extends Component<IProductCard> {
	componentDidMount() {
		AOS.init({ once: true, delay: 100, duration: 800 })
	}

	render() {
		const { tech, index, topic, describe, topicImg, clientImg } = this.props
		const techList: any = tech?.map((tech: any, index: number) => {
			return (
				<div className="card-product-tech" key={index}>
					{tech}
				</div>
			)
		})

		return (
			<div className="card-product-wrapper" data-aos="flip-left">
				<div className="card-row-top">
					<div className="col-flex-2">
						<div className="card-product-index">{index}</div>

						<div>
							<div className="card-product-topic">{topic}</div>
							<div className="card-product-describe">{describe}</div>
						</div>
					</div>
					<div className="col-flex relative">
						<img
							className="card-product-topic-img"
							src={topicImg}
							alt="product-topic-img"
						/>
					</div>
					<div className="col-flex clients">
						{clientImg && (
							<>
								<div className="card-product-clients">Clients</div>

								<img
									className="card-product-clients-img"
									src={clientImg}
									alt="product-client-img"
								/>
							</>
						)}
					</div>
				</div>

				<div className="card-column-bottom">
					<ParallaxBanner
						className="product-parallax-banner"
						layers={[
							{
								children: (
									<div
										className="product-img"
										style={{
											backgroundImage: `url(../..${this.props.img})`,
										}}
									></div>
								),
								amount: 0.2,
							},
						]}
					></ParallaxBanner>
					<div className="card-product-tech-wrapper">{techList}</div>
				</div>
			</div>
		)
	}
}
